import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Play, Pause, Maximize2, ChevronRight, X } from 'lucide-react';
import emailjs from 'emailjs-com';
import { trackVideoInteraction, trackFormSubmission, trackButtonClick } from '../utils/analytics';
import demoVideo from '../assets/EITNextheraDEMO.mp4';
import demoThumbnail from '../assets/screenshotdemo.png';

const DemoSection = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: 'I would like to schedule a demo of NexThera.'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        trackVideoInteraction('Pause', 'Demo Video');
      } else {
        videoRef.current.play();
        trackVideoInteraction('Play', 'Demo Video');
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleFullscreen = () => {
    if (videoRef.current) {
      if (!document.fullscreenElement) {
        videoRef.current.requestFullscreen();
        trackVideoInteraction('Fullscreen Enter', 'Demo Video');
      } else {
        document.exitFullscreen();
        trackVideoInteraction('Fullscreen Exit', 'Demo Video');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('');

    trackFormSubmission('Demo Request', 'Started');

    emailjs.send(
      'service_og52xqi', 
      'template_mq7rlvw', 
      formData,
      '-ziDXkRa6ai7p9Oqh'
    )
    .then((result) => {
      setSubmitStatus('success');
      trackFormSubmission('Demo Request', 'Success');
      setTimeout(() => {
        setShowModal(false);
        setSubmitStatus('');
        setFormData({
          name: '',
          email: '',
          company: '',
          message: 'I would like to schedule a demo of NexThera.'
        });
      }, 2000);
    })
    .catch((error) => {
      setSubmitStatus('error');
      trackFormSubmission('Demo Request', 'Failed');
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    trackVideoInteraction('Completed', 'Demo Video');
  };

  const handleScheduleDemo = () => {
    trackButtonClick('Schedule Demo', 'Demo Section');
    setShowModal(true);
  };

  return (
    <Section id="demo">
      <Content>
        <Header>
          <Title>See NexThera in Action</Title>
          <Description>
            Watch how our AI-powered platform revolutionizes cancer treatment decision-making through personalized recommendations and real-time analysis.
          </Description>
        </Header>

        <VideoContainer>
          <VideoWrapper>
            <Video 
              ref={videoRef}
              poster={demoThumbnail}
              onEnded={handleVideoEnd}
            >
              <source src={demoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>

            <ControlsOverlay>
              <ControlsBar>
                <PlayButton onClick={handlePlayPause}>
                  {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                </PlayButton>

                <RightControls>
                  <FullscreenButton onClick={handleFullscreen}>
                    <Maximize2 size={20} />
                  </FullscreenButton>
                </RightControls>
              </ControlsBar>
            </ControlsOverlay>
          </VideoWrapper>

          <FeatureGrid>
            {[
              {
                title: "AI-Powered Analysis",
                description: "Real-time processing of patient data for optimal treatment recommendations"
              },
              {
                title: "Personalized Care",
                description: "Tailored treatment plans based on individual patient profiles"
              },
              {
                title: "Clinical Guidelines",
                description: "Adherence to latest ESMO guidelines and clinical standards"
              }
            ].map((feature, index) => (
              <FeatureCard key={index}>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureCard>
            ))}
          </FeatureGrid>
        </VideoContainer>

        <CTAContainer>
          <CTAButton onClick={handleScheduleDemo}>
            Schedule a Demo
            <ChevronRight size={20} style={{ marginLeft: '8px' }} />
          </CTAButton>
        </CTAContainer>
      </Content>

      {showModal && (
        <ModalOverlay>
          <Modal>
            <ModalHeader>
              <ModalTitle>Schedule a Demo</ModalTitle>
              <CloseButton onClick={() => setShowModal(false)}>
                <X size={24} />
              </CloseButton>
            </ModalHeader>
            <ModalContent>
              <form onSubmit={handleSubmit}>
                <FormField>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Company/Organization</Label>
                  <Input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Message (Optional)</Label>
                  <TextArea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={4}
                  />
                </FormField>
                <SubmitButton 
                  type="submit" 
                  disabled={isSubmitting}
                  className={submitStatus}
                >
                  {isSubmitting ? 'Sending...' : 
                   submitStatus === 'success' ? 'Sent Successfully!' :
                   submitStatus === 'error' ? 'Failed to Send' :
                   'Send Request'}
                </SubmitButton>
              </form>
            </ModalContent>
          </Modal>
        </ModalOverlay>
      )}
    </Section>
  );
};

const Section = styled.section`
  width: 100%;
  padding: 6rem 1rem;
  background: linear-gradient(to bottom, #f7fafc, #ffffff);
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  max-width: 600px;
  margin: 0 auto;
  color: #64748b;
`;

const VideoContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background: white;
`;

const VideoWrapper = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  background: #1a1a1a;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const ControlsOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  transition: background 0.3s ease;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${VideoWrapper}:hover & {
    opacity: 1;
  }
`;

const ControlsBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlayButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #3b82f6;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #2563eb;
  }
`;

const RightControls = styled.div`
  display: flex;
  gap: 1rem;
`;

const FullscreenButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.5rem;
  background: white;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 0.875rem;
  color: #64748b;
`;

const CTAContainer = styled.div`
  text-align: center;
  margin-top: 3rem;
`;

const CTAButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
`;

const Modal = styled.div`
  background: white;
  border-radius: 1rem;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
`;

const ModalTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s ease;

  &:hover {
    color: #1a1a1a;
  }
`;

const ModalContent = styled.div`
  padding: 1.5rem;
`;

const FormField = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.2s ease;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.success {
    background: #10b981;
  }

  &.error {
    background: #ef4444;
  }
`;

export default DemoSection;