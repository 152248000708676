import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterContainer>
      <CopyrightText>© 2024 NexThera. All Rights Reserved.</CopyrightText>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #282c34; /* Dark background similar to your navbar */
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 0.9rem;
`;

const CopyrightText = styled.p`
  margin: 0;
`;

export default Footer;
