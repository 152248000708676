import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Heart, Globe, Shield } from 'lucide-react';

const About = () => {
  return (
    <Section id="about">
      <h2>About NexThera</h2>
      <Description>
        NexThera is on a mission to transform cancer care through innovative AI-driven solutions, 
        offering hope and personalized treatment strategies to patients worldwide.
      </Description>
      <ValueGrid>
        {[
          { icon: <Heart size={32} />, title: "Our Mission", description: "To improve cancer patients' lives by revolutionizing treatment through AI-powered personalization." },
          { icon: <Globe size={32} />, title: "Our Vision", description: "A world where every cancer patient receives the most effective, tailored treatment possible." },
          { icon: <Shield size={32} />, title: "Our Values", description: "Innovation, Compassion, Integrity, and Collaboration drive everything we do." }
        ].map((value, index) => (
          <ValueCard key={index}>
            <IconWrapper>{value.icon}</IconWrapper>
            <h3>{value.title}</h3>
            <p>{value.description}</p>
          </ValueCard>
        ))}
      </ValueGrid>
    </Section>
  );
};

const Section = styled.section`
  padding: 100px 0;
  background-color: #ffffff;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 60px;
  color: #333;
`;

const ValueGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const ValueCard = styled(motion.div)`
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  width: 300px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }

  h3 {
    color: #1a73e8;
    margin: 20px 0;
    font-size: 1.4rem;
  }

  p {
    color: #555;
    font-size: 1rem;
  }
`;

const IconWrapper = styled.div`
  background: #1a73e8;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(26, 115, 232, 0.3);
`;

export default About;