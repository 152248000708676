export const logEvent = (category, action, label) => {
    if (window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
      });
    }
  };
  
  // Commonly used events
  export const trackButtonClick = (buttonName, section) => {
    logEvent('Button Click', buttonName, section);
  };
  
  export const trackFormSubmission = (formName, status) => {
    logEvent('Form Submission', formName, status);
  };
  
  export const trackVideoInteraction = (action, videoName) => {
    logEvent('Video', action, videoName);
  };
  
  export const trackNavigation = (destination) => {
    logEvent('Navigation', 'Section Change', destination);
  };