import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Mail, Phone, MapPin } from 'lucide-react';
import emailjs from 'emailjs-com';  // Import EmailJS

const Contact = () => {
  const form = useRef();  // Reference to the form

  // Function to send the email using EmailJS
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_og52xqi', 'template_mq7rlvw', form.current, '-ziDXkRa6ai7p9Oqh')
      .then((result) => {
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message.');
      });
  };

  return (
    <Section id="contact">
      <Content>
        <motion.h2
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Contact Us
        </motion.h2>
        
        {/* Attach form ref and handle onSubmit */}
        <ContactForm ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" placeholder="Your Message" required></textarea>
          <SubmitButton
            type="submit"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Send Message
          </SubmitButton>
        </ContactForm>
      </Content>
    </Section>
  );
};

const Section = styled.section`
  padding: 100px 20px;
  background-color: #f7fafc;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 50px;
    background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const IconWrapper = styled.div`
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  input, textarea {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    font-size: 1rem;
  }

  textarea {
    height: 150px;
  }
`;

const SubmitButton = styled(motion.button)`
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 15px rgba(59, 130, 246, 0.4);
  }
`;

export default Contact;
