import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ArrowRight, Activity, Brain, Microscope } from 'lucide-react';
import { trackButtonClick, trackNavigation } from '../utils/analytics';

const HeroSection = () => {
  const containerRef = useRef(null);
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 300], [0, -50]);
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);

  const scrollToDemo = () => {
    trackButtonClick('Watch Demo', 'Hero Section');
    const demoSection = document.getElementById('demo');
    if (demoSection) {
      demoSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLearnMore = () => {
    trackButtonClick('Learn More', 'Hero Section');
    trackNavigation('Technology Section');
    document.getElementById('technology').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <HeroContainer ref={containerRef}>
      <BackgroundGradient />
      <GridPattern />
      
      <FloatingIcons>
        <FloatingIcon
          animate={{
            y: [-10, 10, -10],
            rotate: [0, 5, -5, 0],
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          style={{ left: '10%', top: '20%' }}
        >
          <Brain size={32} />
        </FloatingIcon>
        <FloatingIcon
          animate={{
            y: [-15, 15, -15],
            rotate: [0, -5, 5, 0],
          }}
          transition={{
            duration: 6,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          style={{ right: '15%', top: '30%' }}
        >
          <Activity size={32} />
        </FloatingIcon>
        <FloatingIcon
          animate={{
            y: [-12, 12, -12],
            rotate: [0, 5, -5, 0],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          style={{ left: '20%', bottom: '30%' }}
        >
          <Microscope size={32} />
        </FloatingIcon>
      </FloatingIcons>

      <ContentWrapper style={{ y, opacity }}>
        <Content>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Title>
              Revolutionize
              <GradientSpan> Cancer </GradientSpan>
              Treatment
            </Title>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <Description>
              NexThera combines AI technology with oncology expertise to provide personalized treatment solutions for advanced-stage cancer patients.
            </Description>
          </motion.div>
          <ButtonGroup>
            <PrimaryButton
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={scrollToDemo}
            >
              Watch Demo <ArrowRight size={20} />
            </PrimaryButton>
            <SecondaryButton
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleLearnMore}
            >
              Learn More
            </SecondaryButton>
          </ButtonGroup>
        </Content>
      </ContentWrapper>
    </HeroContainer>
  );
};

const HeroContainer = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: #f8fafc;
`;

const BackgroundGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(59, 130, 246, 0.1) 0%,
    rgba(147, 51, 234, 0.1) 100%
  );
  z-index: 1;
`;

const GridPattern = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  z-index: 2;
`;

const FloatingIcons = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const FloatingIcon = styled(motion.div)`
  position: absolute;
  color: rgba(59, 130, 246, 0.6);
  filter: drop-shadow(0 0 10px rgba(59, 130, 246, 0.3));
`;

const ContentWrapper = styled(motion.div)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  padding: 0 2rem;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding-top: 4rem;
`;

const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const GradientSpan = styled.span`
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.8;
  color: #4a5568;
  margin-bottom: 2.5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 3rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BaseButton = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const PrimaryButton = styled(BaseButton)`
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  color: white;
  border: none;
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);

  &:hover {
    box-shadow: 0 6px 8px rgba(59, 130, 246, 0.3);
  }
`;

const SecondaryButton = styled(BaseButton)`
  background: transparent;
  color: #3b82f6;
  border: 2px solid #3b82f6;

  &:hover {
    background: rgba(59, 130, 246, 0.1);
  }
`;



export default HeroSection;