import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Brain, Database, Network } from 'lucide-react';

const Technology = () => {
  const technologies = [
    {
      icon: <Brain size={32} />,
      title: "Advanced AI Models",
      description: "Proprietary machine learning algorithms analyze complex medical data to predict optimal treatment paths with high accuracy.",
      delay: 0.2
    },
    {
      icon: <Database size={32} />,
      title: "Data Integration",
      description: "Seamless integration of genetic, proteomic, and clinical data to create comprehensive patient profiles.",
      delay: 0.3
    },
    {
      icon: <Network size={32} />,
      title: "Real-time Analysis",
      description: "Instant processing capabilities allow for immediate treatment recommendations based on the latest patient data.",
      delay: 0.4
    }
  ];

  return (
    <Section id="technology">
      <BackgroundGlow />
      <ContentWrapper>
        <Header>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <Title>Technology</Title>
            <Subtitle>
              Our innovative tech stack combines advanced AI, big data analytics, and medical expertise
              to deliver unparalleled precision in cancer treatment recommendations.
            </Subtitle>
          </motion.div>
        </Header>

        <TechGrid>
          {technologies.map((tech, index) => (
            <TechCard
              key={index}
              as={motion.div}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: tech.delay }}
              whileHover={{ y: -5, transition: { duration: 0.2 } }}
            >
              <IconWrapper>
                {tech.icon}
                <IconGlow />
              </IconWrapper>
              <TechTitle>{tech.title}</TechTitle>
              <TechDescription>{tech.description}</TechDescription>
            </TechCard>
          ))}
        </TechGrid>
      </ContentWrapper>
    </Section>
  );
};

const Section = styled.section`
  padding: 100px 0;
  background: #f8fafc;
  position: relative;
  overflow: hidden;
`;

const BackgroundGlow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(59, 130, 246, 0.1) 0%,
    rgba(147, 51, 234, 0.1) 50%,
    transparent 70%
  );
  z-index: 1;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 2;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #64748b;
  max-width: 800px;
  margin: 0 auto;
`;

const TechGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 0 auto;
  max-width: 1200px;
`;

const TechCard = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
`;

const IconWrapper = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 20px;
`;

const IconGlow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  filter: blur(10px);
  opacity: 0.5;
  z-index: -1;
`;

const TechTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1a1a1a;
`;

const TechDescription = styled.p`
  color: #64748b;
  line-height: 1.6;
  font-size: 1.1rem;
`;

export default Technology;