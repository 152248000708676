import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Linkedin, Twitter } from 'lucide-react';
import sofia from '../assets/sofia.png'; 
import joao from '../assets/joao.jpg';
import advisors from '../assets/advisors.png';

const Team = () => {
  const teamMembers = [
    { 
      name: 'João Rocha Gomes', 
      role: 'Founder & CEO', 
      description: 'Medical Doctor, Health Economist, PhD Candidate Data Science for Health', 
      image: joao,
      linkedin: 'https://www.linkedin.com/in/joaorochagomes/' 
    },
    { 
      name: 'Sofia Teixeira', 
      role: 'Founder & CTO', 
      description: 'Msc Electrical Engineering, PhD Candidate in Informatics Engineering', 
      image: sofia,
      linkedin: 'https://www.linkedin.com/in/ana-sofia-teixeira/' 
    },
    { 
      name: 'Advisory Board', 
      role: 'Expert Panel', 
      description: 'Specialists in Health Data Science, Oncology, and Management', 
      image: advisors 
    }
  ];

  return (
    <Section id="team">
      <Content>
        <motion.h2
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Meet Our Team
        </motion.h2>
        <Grid>
          {teamMembers.map((member, index) => (
            <Card
              key={index}
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 + index * 0.2 }}
            >
              <Avatar src={member.image} alt={member.name} />
              <h3>{member.name}</h3>
              <Role>{member.role}</Role>
              <Description>{member.description}</Description>
              <SocialLinks>
                {member.linkedin && (
                  <SocialIcon href={member.linkedin} target="_blank" aria-label="LinkedIn">
                    <Linkedin size={20} />
                  </SocialIcon>
                )}
              </SocialLinks>
            </Card>
          ))}
        </Grid>
      </Content>
    </Section>
  );
};

const Section = styled.section`
  padding: 100px 20px;
  background-color: #ffffff;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
    background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;

const Card = styled(motion.div)`
  background-color: #f7fafc;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
`;

const Role = styled.p`
  font-size: 1.1rem;
  color: #3b82f6;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 20px;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const SocialIcon = styled.a`
  color: #3b82f6;
  transition: color 0.3s ease;

  &:hover {
    color: #9333ea;
  }
`;

export default Team;
